import './font/stylesheet.css'
import './style.css'
import "./skin.css"

import Alpine from 'alpinejs'
import videojs from 'video.js'
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse);
window.Alpine = Alpine;

Alpine.start();

document.addEventListener('alpine:init', () => {
    console.info("init");
})


window.direttaTV = (id) =>{
    const player = videojs(id, {
        controls: true,
        autoplay: true,
        preload: 'auto',
        muted: true
      });
}


window.direttaRadio = (id) =>{
    const player = videojs(id, {
        controls: true,
        autoplay: true,
        preload: 'auto',
        "inactivityTimeout": 0,
        audioOnlyMode: true,
        liveui: true,
        audioPosterMode: false,
        controlBar: {
            playToggle: true,
            captionsButton: false,
            chaptersButton: false,
            subtitlesButton: false,
            remainingTimeDisplay: false,
            progressControl: {
              seekBar: true
            },
            fullscreenToggle: false,
            playbackRateMenuButton: false,
          }
      });
}


window.shareOnFacebook = function (url) {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url), 'facebook-share-dialog', 'width=626,height=436');
}

window.shareOnLinkedIn= function (url, title, summary) {
    window.open('https://www.linkedin.com/shareArticle?url=' + encodeURIComponent(url) + '&title=' + encodeURIComponent(title) + '&summary=' + encodeURIComponent(summary), 'linkedin-share-dialog', 'width=750,height=750');
}

window.shareOnTwitter= function (url, text) {
    const hashtags = "laconair";
    window.open('https://twitter.com/share?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(text) + '&hashtags=' + encodeURIComponent(hashtags), 'twitter-share-dialog', 'width=626,height=436');
}

window.shareOnWhatsApp= function (url, title) {
    window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(title + ' ' + url));
}

window.shareViaEmail= function (to, subject, body) {
    window.location.href = 'mailto:' + encodeURIComponent(to) + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
}